import lazyLoadImages from "./components/lazyLoad";
import nav from "./components/navbar.js";
import map from "./components/map.js";
import cookie from "./components/cookie-popup.js";
require("fslightbox");
lazyLoadImages();
document.body.addEventListener("force_lazyload", (e) => {
  lazyLoadImages();
});

document.addEventListener("DOMContentLoaded", () => {
  const Nav = new nav();
  const mapElement = document.querySelector("#map");
  const Cookie = new cookie();
  // const langauge = new Language();

  if (typeof mapElement != "undefined" && mapElement != null) {
    const Map = new map(mapElement, map_data);
  }
});
