import { disableScroll, enableScroll } from "./_helpers.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class Navbar {
  constructor() {
    this.hamburgerButton = document.querySelector(".hamburger");
    this.overlay = document.querySelector(".navbar-overlay");
    this.dotContainer = document.querySelector(".dot-container");
    this.hamburgerBarFirst = document.querySelector(".first-bar");
    this.hamburgerMiddleBar = document.querySelector(".middle-bar");
    this.hamburgerLastBar = document.querySelector(".last-bar");
    this.navContent = document.querySelector(".navbar-content");
    this.header = document.querySelector("#header");
    this.headerOverlay = document.querySelector(".header-overlay");

    this.isMenuOpen = false;
    this.tl = gsap.timeline({ onComplete: this.toggleNavClass });
    this.initTimeLine();
    this.initListeners();
    this.headerIncrease();
  }

  initTimeLine = () => {
    this.tl.to(this.overlay, { height: "100vh ", width: "100%", ease: "sine.out", duration: 0.5 }, "start");
    this.tl.to(this.dotContainer, { opacity: 0, duration: 0.5 }, "start");
    this.tl.to(this.hamburgerBarFirst, { y: "9px", duration: 0.1, ease: "sine.out" }, "start");
    this.tl.to(this.headerOverlay, { duration: 0.1, opacity: 0 }, "start");
    this.tl.to(this.hamburgerLastBar, { x: "10px", opacity: 0, duration: 0.1 }, "start");
    this.tl.to(this.hamburgerBarFirst, { rotate: -45, delay: 0.1, duration: 0.2 }, "start");
    this.tl.to(this.hamburgerMiddleBar, { rotate: 45, delay: 0.1, duration: 0.2 }, "start");
    // this.tl.to(this.navContent, { x: 0, duration: 0.2, ease: "sine.out" }, "end");

    this.tl.pause();
  };

  initListeners = () => {
    this.hamburgerButton.addEventListener("click", this.handleToggleMenu);
  };

  toggleNavClass = () => {
    this.navContent.classList.toggle("is-showed");
  };

  headerIncrease = () => {
    ScrollTrigger.create({
      start: "top -250px",
      end: 99999,
      toggleClass: { className: "header--scrolled", targets: this.header },
    });
  };

  handleToggleMenu = () => {
    if (!this.isMenuOpen) {
      this.isMenuOpen = !this.isMenuOpen;
      disableScroll();
      this.tl.play();
    } else {
      this.isMenuOpen = !this.isMenuOpen;
      enableScroll();

      setTimeout(() => {
        this.tl.reverse();
      }, 300);

      this.toggleNavClass();
    }
  };
}

export default Navbar;
