import leaflet from "leaflet";

class Map {
  constructor(mapElement, mapData) {
    this.mapElement = mapElement;
    this.lat = mapData.lat;
    this.lng = mapData.lng;
    this.zoom = mapData.zoom;
    this.marker = {
      url: mapData.markerUrl,
      title: mapData.markerTitle,
      marker: mapData.marker,
    };

    this.lazyInit(this.mapElement, this.initMap);
  }

  openGoogleMaps = () => {
    console.log("test");
    window.open(this.marker.url, "_blank");
  };

  initMap = () => {
    const map = L.map(this.mapElement).setView([this.lat, this.lng], this.zoom, { zoomControl: false });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: "© OpenStreetMap",
    }).addTo(map);

    //adding custom marker to map
    const mapMarker = L.icon({
      iconUrl: this.marker.marker,
      iconSize: [54, 66],
      iconAnchor: [17, 51],
      clickable: true,
    });

    L.marker([this.lat, this.lng], { icon: mapMarker }).addTo(map).on("click", this.openGoogleMaps);
  };

  //Intersection observer for map lazy loding
  lazyInit = (element, mapInit) => {
    const observer = new IntersectionObserver((entries) => {
      if (entries.some(({ isIntersecting }) => isIntersecting)) {
        observer.disconnect();
        mapInit();
      }
    });
    observer.observe(element);
  };
}

export default Map;
